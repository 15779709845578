<template>
  <component :is="'b-card'">
    <edit-tab class="mt-1"/>
  </component>
</template>

<script>
import { BCard } from 'bootstrap-vue'
import { onUnmounted } from '@vue/composition-api'

import store from '@/store'
import itemStoreModule from '../propostaStoreModule'
import EditTab from './EditTab.vue'

export default {
  components: {
    BCard,
    EditTab,
  },
  setup() {
    const ITEM_APP_STORE_MODULE_NAME = 'app-propostas'

    // Register module
    if (!store.hasModule(ITEM_APP_STORE_MODULE_NAME)) store.registerModule(ITEM_APP_STORE_MODULE_NAME, itemStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(ITEM_APP_STORE_MODULE_NAME)) store.unregisterModule(ITEM_APP_STORE_MODULE_NAME)
    })
  },
}
</script>
