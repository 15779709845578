<template>
  <div>
    <b-row class="mb-2">
      <b-col
          col
          lg="6"
      >
        <h4> Editar Proposta : {{ dadosItem.codigo_proposta }}</h4>
      </b-col>

    </b-row>

    <hr/>
    <b-row>
      <b-col cols="3">
        <b-form-group
            label="Tipo de Proposta"
            label-for="tipo_proposta"
        >
          <div class="d-flex mb-2">
            <b-form-radio
                v-model="dadosItem.tipo_proposta"
                class="mr-2"
                name="tipo_proposta-radios"
                value="LEAD"
            >
              LEAD
            </b-form-radio>
            <b-form-radio
                v-model="dadosItem.tipo_proposta"
                name="tipo_proposta-radios"
                value="CLIENTE"
            >
              CLIENTE
            </b-form-radio>
          </div>

        </b-form-group>
      </b-col>
      <b-col cols="6">
        <b-form-group
            :label="dadosItem.tipo_proposta ==='CLIENTE' ? 'Cliente' : 'Lead'"
            label-for="tipoCliente"
            v-if="dadosItem.tipo_proposta"
        >
          <select-com-pesquisa
              :itens-para-edicao="[dadosItem.cliente]"
              v-if="dadosItem.tipo_proposta ==='CLIENTE'"
              placeholder="Escolha um cliente"
              url="/clientes"
              :multiple="false"
              :disabled="false"
              @selecionados="tratarEventoClienteSelecionado"
          />
          <select-com-pesquisa
              :itens-para-edicao="[dadosItem.lead]"
              v-if="dadosItem.tipo_proposta ==='LEAD'"
              placeholder="Escolha um lead"
              url="/leads"
              :multiple="false"
              :disabled="false"
              @selecionados="tratarEventoLeadSelecionado"
          />
        </b-form-group>
      </b-col>
      <b-col cols="3">
        <b-form-group
            label="Data Solicitação"
            label-for="data_solicitacao"
        >
          <b-form-input
              type="date"
              prepend="Data Solicitação"
              v-model="dadosItem.data_solicitacao"
              placeholder="Data Solicitação"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="3">
        <b-form-group
            label="Data Entrega"
            label-for="data_entrega"
        >
          <b-form-input type="date" prepend="Data Entrega" v-model="dadosItem.data_entrega" placeholder="Data Entrega"/>
        </b-form-group>
      </b-col>
      <b-col cols="3">
        <b-form-group
            label="Empresa"
            label-for="empresa"
        >
          <select-com-pesquisa
              :itens-para-edicao="[dadosItem.empresa]"
              placeholder="Escolha uma empresa"
              url="/empresas"
              :multiple="false"
              :disabled="false"
              @selecionados="tratarEventoEmpresaSelecionada"
          />
        </b-form-group>
      </b-col>
      <b-col cols="6">
        <b-form-group
            label="Responsável"
            label-for="responsavel"
        >
          <select-com-pesquisa
              :itens-para-edicao="[dadosItem.responsavel]"
              placeholder="Escolha um responsável"
              url="/users?role=Líder"
              :multiple="false"
              :disabled="false"
              @selecionados="tratarEventoResponsavelSelecionado"
          />
        </b-form-group>
      </b-col>
      <b-col cols="4">
        <b-form-group
            label="Solicitante"
            label-for="solicitante"
        >
          <b-form-input
              v-model="dadosItem.solicitante"
              placeholder="Informe o solicitante"
              type="text"
          />
        </b-form-group>
      </b-col>
      <b-col cols="2">
        <b-form-group
            label="Possui Parceiro"
            label-for="existe_parceiro"
        >
          <div class="d-flex">
            <b-form-radio
                v-model="existe_parceiro"
                class="mr-2"
                name="radio-existe_parceiro"
                :value="true"
            >
              SIM
            </b-form-radio>
            <b-form-radio
                v-model="existe_parceiro"
                class="mr-2"
                name="radio-existe_parceiro"
                :value="false"
            >
              NÃO
            </b-form-radio>
          </div>

        </b-form-group>
      </b-col>

      <b-col cols="6">
        <b-form-group
            label="Escolha um parceiro"
            label-for="parceiro"
            v-if="existe_parceiro"
        >
          <select-com-pesquisa
              :itens-para-edicao="[dadosItem.parceiro]"
              v-if="existe_parceiro"
              placeholder="Escolha um parceiro"
              url="/clientes"
              :multiple="false"
              :disabled="false"
              @selecionados="tratarEventoParceiroSelecionado"
          />

        </b-form-group>
      </b-col>
      <b-col cols="12">
        <b-form-group
            label="Descrição"
            label-for="descricao"
        >
          <quill-editor-pensou :item-edicao="dadosItem.descricao" @input="tratarDescricao" toolbar-id="toolbar"
          />

        </b-form-group>
      </b-col>
      <b-col cols="3">
        <b-form-group
            label="Tipo de Custos"
            label-for="tipo_custo"
        >

          <div class="d-flex mb-2">
            <b-form-radio
                v-model="dadosItem.tipo_custo"
                class="mr-2"
                name="tipo_custo-radios"
                value="BIM"
            >
              BIM
            </b-form-radio>
            <b-form-radio
                v-model="dadosItem.tipo_custo"
                name="tipo_custo-radios"
                value="CAD"
            >
              CAD
            </b-form-radio>
          </div>

        </b-form-group>
      </b-col>
      <b-col cols="5">
        <b-form-group
            label="Nome Proposta"
            label-for="nome"
        >
          <b-form-input
              v-model="dadosItem.nome"
              placeholder="Informe o nome da proposta"
              type="text"
          />
        </b-form-group>
      </b-col>
      <b-col cols="4">
        <b-form-group
            label="Status"
            label-for="status"
        >
          <select-com-pesquisa
              :itens-para-edicao="[this.dadosItem.status]"
              placeholder="Escolha um status"
              url="/status?modulo=propostas&setor=comercial"
              :multiple="false"
              :disabled="false"
              select-first-option
              @selecionados="tratarEventoStatusSelecionado"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <div class="d-flex justify-content-between">
      <div>
        <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            v-b-modal.modal-scrollable
            variant="outline-primary"
        >
          Adicionar Subdisciplinas
        </b-button>
      </div>

      <div class="font-weight-bold d-flex align-items-center" v-if="listaSubsComputed">
        Valor da proposta: {{
          calcValorDisc(Object.values(listaSubsComputed)
              .flat())
        }}
      </div>
    </div>
    <b-row>

      <b-col cols="12" class="mt-2">
        <b-form-group
            label-for="subdisciplinas"
        >
          <b-list-group>
            <div v-for="(itens, chave) in listaSubsComputed" :key="chave">
              <b-list-group-item active v-if="itens.length > 0">
                <div class="d-flex justify-content-between">
                  <div>
                    {{ chave }}
                    <b-badge
                        variant="dark"
                        pill
                        class="badge-round"
                    >
                      {{ itens.length }}
                    </b-badge>
                  </div>
                  <div>{{ calcValorDisc(itens) }}</div>
                </div>
              </b-list-group-item>
              <b-list-group-item v-for="sub in itens" :key="sub.uuid">
                <div class="d-flex justify-content-between ">
                  <div class="font-size-7 d-flex align-items-center">
                    {{ sub.nome }}
                  </div>
                  <div class="d-flex font-size-8">

                    <b-form-spinbutton
                        style="width: 100px"
                        id="sb-inline"
                        class="form-control-sm"
                        v-model="sub.quantidade"
                        inline
                        size="sm"
                    />
                    <b-form-input
                        class="ml-2"
                        style="width: 120px"
                        size="sm"
                        v-model="sub.valor"
                        v-money="{
                                      decimal: ',',
                                      thousands: '.',
                                      prefix: 'R$ ',
                                      precision:2,
                                      masked: false,
                                      allowBlank: true,
                                      minimumNumber: 0
                                    }"
                    />
                    <b-button variant="danger" size="sm" class="ml-2" @click="removerSub(sub)">
                      <feather-icon icon="TrashIcon" size="16"/>
                    </b-button>
                  </div>
                </div>
              </b-list-group-item>
            </div>
          </b-list-group>
        </b-form-group>
      </b-col>
    </b-row>
    <b-modal
        id="modal-scrollable"
        scrollable
        centered
        size="lg"
        title="Adicionar subdisciplinas a proposta"
        cancel-title="Fechar"
        ok-title="Adicionar"
        @ok="AdicionarSubdisciplinas"
        @cancel="subsFiltradasModal = []"
        ok-variant="success"
        cancel-variant="outline-secondary"

    >
      <div class="modal-body">
        <b-row>
          <b-col cols="12">
            <select-com-pesquisa
                :itensParaEdicao="[]"
                placeholder="Escolha a Disciplina"
                url="/disciplinas"
                :multiple="false"
                @selecionados="tratarEventoDisciplinasSelecionadas"
            />
          </b-col>
        </b-row>
        <div class="d-flex justify-content-center py-3" v-if="loadModal">
          <b-spinner variant="primary" label="Carregando subdisciplinas..."/>
        </div>
        <b-row v-if="subsFiltradasModal.length">
          <b-col cols="12" class="mt-2">
            <h6 class="text-uppercase">Escolha as subdisciplinas</h6>
            <b-form-checkbox-group
                id="checkbox-group-subs"
                v-model="subsSelecionadasModal"
                name="subsSelecionadas"
                v-if="dadosItem.tipo_custo === 'BIM'"
            >
              <b-form-checkbox
                  class="d-block"
                  v-for="sub in subsFiltradasModal"
                  :key="sub.uuid"
                  :value="{uuid:sub.uuid, tipo_custo:'BIM', nome:sub.nome, valor:sub.valor_bim, disciplina:sub.disciplinas[0].nome, quantidade: 1}"
              >
                <span class="nomeItemCheck"> {{ sub.nome }}</span>
              </b-form-checkbox>
            </b-form-checkbox-group>
            <b-form-checkbox-group
                id="checkbox-group-subs"
                v-model="subsSelecionadasModal"
                name="subsSelecionadas"
                v-else
            >
              <b-form-checkbox
                  class="d-block"
                  v-for="sub in subsFiltradasModal"
                  :key="sub.uuid"
                  :value="{uuid:sub.uuid, tipo_custo:'CAD', nome:sub.nome, valor:sub.valor_cad, disciplina:sub.disciplinas[0].nome, quantidade: 1}"
              >
                <span class="nomeItemCheck"> {{ sub.nome }}</span>
              </b-form-checkbox>
            </b-form-checkbox-group>
          </b-col>
        </b-row>
      </div>
    </b-modal>

    <!-- Action Buttons -->
    <b-col class="text-right mt-2 pr-0">
      <b-button
          variant="outline-secondary"
          type="button"
          :to="{ name: 'propostas' }"
      >
        Voltar
      </b-button>
      <b-button
          type="button"
          variant="success"
          class="mb-1 mb-sm-0 mr-0 ml-sm-1"
          @click="editarItem(dadosItem)"
      >
        Salvar
      </b-button>
    </b-col>
  </div>
</template>

<script>

import {
  BBadge,
  BButton,
  BCard,
  BCol,
  BForm,
  BFormCheckbox,
  BFormCheckboxGroup,
  BFormFile,
  BFormGroup,
  BFormInput,
  BFormRadio,
  BFormSpinbutton,
  BFormTags,
  BImg,
  BInputGroup,
  BInputGroupAppend,
  BListGroup,
  BListGroupItem,
  BModal,
  BRow,
  BSpinner,
  VBModal,
} from 'bootstrap-vue'
import SelectComPesquisa from '@/views/components/componentesPensou/SelectComPesquisa.vue'
import useItemList from '../list/useItemList.js'
import QuillEditorPensou from '@/views/forms/form-element/quill-editor/QuillEditorPensou.vue'
import moment from 'moment-timezone'
import { formatarParaMoeda, limpaMoeda } from '@core/utils/filter'
import axios from '@/libs/axios'
import Ripple from 'vue-ripple-directive'

export default {

  components: {
    BFormSpinbutton,
    BListGroupItem,
    BListGroup,
    BBadge,
    BModal,
    BFormCheckbox,
    BFormCheckboxGroup,
    BSpinner,
    BFormRadio,
    QuillEditorPensou,
    BCard,
    BFormFile,
    BInputGroupAppend,
    BImg,
    SelectComPesquisa,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BFormTags,
    BInputGroup,

  },
  setup() {
    const {
      getItem,
      update
    } = useItemList()

    return {
      getItem,
      update
    }
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      dadosItem: {
        listaSubsSelecionadas: {}
      },
      editorOption: {
        modules: {
          toolbar: '#quill-toolbar',
        },
        placeholder: 'Informe uma descrição',
      },
      subsFiltradasModal: [],
      subsSelecionadasModal: [],
      existe_parceiro: false,
      loadModal: false,
      previousValue: 0,
    }
  },
  computed: {
    listaSubsComputed() {
      return this.dadosItem.listaSubsSelecionadas
    },

  },
  watch: {
    'dadosItem.tipo_custo': {
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.$set(this, 'subsSelecionadas', {})
        }
      },
      deep: true,
    },

  },
  async mounted() {
    this.dadosItem = await this.getItem(this.$route.params.id)
    this.atribuirCampos()
  },
  methods: {
    atribuirCampos() {
      this.dadosItem.tipo_proposta = this.dadosItem.cliente ? 'CLIENTE' : 'LEAD'
      this.existe_parceiro = !!(this.dadosItem.parceiro && Object.keys(this.dadosItem.parceiro).length > 0)
      this.dadosItem.data_entrega = moment(this.dadosItem.data_entrega)
          .format('YYYY-MM-DD')
      this.dadosItem.data_solicitacao = moment(this.dadosItem.data_solicitacao)
          .format('YYYY-MM-DD')
      this.dadosItem.listaSubsSelecionadas = this.montarSubsParaEdicao(this.dadosItem.subdisciplinas)

    },
    editarItem() {

      let itensProposta = { ...this.dadosItem }
      itensProposta.subdisciplinas = []
      for (const subdisciplina in this.dadosItem.listaSubsSelecionadas) {

        itensProposta.subdisciplinas.push(...this.dadosItem.listaSubsSelecionadas[subdisciplina])
      }
      itensProposta.subdisciplinas = itensProposta.subdisciplinas.map(item => {
        return {
          ...item,
          valor: limpaMoeda(item.valor),

        }
      })
      itensProposta.cliente = this.dadosItem.cliente?.id
      itensProposta.lead = this.dadosItem.lead?.id
      itensProposta.responsavel = this.dadosItem.responsavel?.id
      itensProposta.parceiro = this.dadosItem.parceiro?.id
      itensProposta.empresa = this.dadosItem.empresa?.id
      itensProposta.status = this.dadosItem.status?.id

      this.update(itensProposta)
    },
    tratarEventoDisciplinasSelecionadas(item) {
      if (!item) {
        this.subsFiltradasModal = []
        return
      }
      this.loadModal = true
      let urlSubdisciplinas = `/subdisciplinas?disciplina=${item.id}&perPage=1000`
      axios
          .get(urlSubdisciplinas)
          .then((respo) => {
            this.loadModal = false
            this.subsFiltradasModal = respo.data.data

            this.subsSelecionadasModal = []
          })
    },

    calcValorDisc(itens) {
      const total = itens.reduce((acc, item) => {
        if (!item.valor) return acc
        return acc + (limpaMoeda(item.valor) * item.quantidade)
      }, 0)
      return formatarParaMoeda(total)
    },
    AdicionarSubdisciplinas() {
      this.subsFiltradasModal = []

      this.subsSelecionadasModal.forEach(item => {
        const {
          disciplina,
          uuid,
          id
        } = item

        if (this.dadosItem.listaSubsSelecionadas[disciplina]) {
          const exists = this.dadosItem.listaSubsSelecionadas[disciplina].some(sub => sub.uuid === uuid)
          if (!exists) {
            this.$set(this.dadosItem.listaSubsSelecionadas[disciplina], this.dadosItem.listaSubsSelecionadas[disciplina].length, item)
          }
        } else {
          this.$set(this.dadosItem.listaSubsSelecionadas, disciplina, [item])
        }
      })
    },
    montarSubsParaEdicao(subs) {
      const subsSelecionadas = {}

      subs.forEach(sub => {
        const { disciplinas } = sub
        const disciplinaNome = disciplinas[0].nome

        sub.uuid = sub.id

        if (subsSelecionadas[disciplinaNome]) {
          subsSelecionadas[disciplinaNome].push(sub)
        } else {
          subsSelecionadas[disciplinaNome] = [sub]
        }
      })
      return subsSelecionadas
    },
    removerSub(sub) {

      const idSub = sub.id || sub.uuid

      for (const disciplina in this.dadosItem.listaSubsSelecionadas) {
        const updatedItems = this.dadosItem.listaSubsSelecionadas[disciplina].filter(item => item.uuid !== idSub)
        this.$set(this.dadosItem.listaSubsSelecionadas, disciplina, updatedItems)
      }

      this.$forceUpdate()
    },

    tratarEventoClienteSelecionado(itens) {
      this.dadosItem.cliente = itens || {}
    },
    tratarEventoResponsavelSelecionado(itens) {
      this.dadosItem.responsavel = itens || {}
    },
    tratarEventoLeadSelecionado(itens) {
      this.dadosItem.lead = itens || {}
    },
    tratarEventoParceiroSelecionado(itens) {
      this.dadosItem.parceiro = itens || {}
    },
    tratarEventoEmpresaSelecionada(itens) {
      this.dadosItem.empresa = itens || {}
    },
    tratarEventoStatusSelecionado(itens) {
      this.dadosItem.status = itens || {}
    },
    tratarDescricao(text) {
      this.dadosItem.descricao = text
    },
  },

}
</script>

<style lang="scss" scoped>
.modal-body {
  min-height: 300px;
  max-height: 500px;
  overflow: scroll;
}

.nomeItemCheck {
  font-size: 0.8rem;
}
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

</style>
